<template>
  <div id="sample-license">
    <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="Sample Songfile License"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="700px"
      :html-to-pdf-options="{ margin: 10 }"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <LicenseTerms />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import LicenseTerms from "@/components/LicenseTerms.vue";

export default {
  name: "SampleLicense",
  components: {
    VueHtml2pdf,
    LicenseTerms
  },
  mounted: function() {
    this.$refs.html2Pdf.generatePdf();
  }
};
</script>

<style lang="scss">
#sample-license {
  width: 800px;
  margin: auto;
  position: relative;

  .btn-download {
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
  }

  .pdf-preview {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;

    > button {
      display: none;
    }

    iframe {
      height: 100%;
    }
  }
}
</style>
